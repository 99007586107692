import React, {useEffect, useState} from "react";
import {ProgressBar} from "react-bootstrap";

const donationApiUrl = "https://api.adropofdelight.org/donations";
const maxTrackerInfo = 10000;
const defaultAmount = 638;

const DonationTracker = () => {
    const [donationAmount, setDonations] = useState(null);

    useEffect(() => {
        getData();
        async function getData() {
            const res = await fetch(donationApiUrl);
            const data = await res.text();
            setDonations(data) ;
        }
    }, [setDonations]);

    return(<ProgressBar className="trackerStyle" variant="info" label={donationAmount && ('$' + donationAmount)} animated now={donationAmount || defaultAmount} max={maxTrackerInfo} />)
}

export default DonationTracker
